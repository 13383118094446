<template>
  <div v-loading="loading" class="teacher-manage">
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-button
          v-if="permissions.cms_schoolstudent_add"
          type="primary"
          @click="addOrUpdateHandle()"
          ><i class="el-icon-plus"></i>新增老师信息</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="importHandle()"
          ><i class="el-icon-files"></i>导入老师信息</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="exportTeacher()"
          ><i class="el-icon-files"></i>导出老师信息</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="importFollowStudent()"
          ><i class="el-icon-files"></i>导入重点关注学生信息</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="exportFollowStudent()"
          ><i class="el-icon-files"></i>导出重点关注学生信息</el-button
        >
      </el-form-item>
    </el-form>
    <div class="search-teacher">
      <div class="search-items">
        <el-input v-model="teacherName" placeholder="请输入搜索词"></el-input>
        <el-button type="primary" @click="searchTeacher()">
          <i class="el-icon-search"></i>搜索
        </el-button>
      </div>
    </div>

    <el-table
      v-loading="dataListLoading"
      :data="dataList"
      border
      class="teacher-detail"
    >
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="教师姓名"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        header-align="center"
        align="center"
        label="系统用户名"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        header-align="center"
        align="center"
        label="联系电话"
      >
      </el-table-column>

      <el-table-column
        prop="subjectName"
        header-align="center"
        align="center"
        label="科目"
      >
      </el-table-column>
      <!--      <el-table-column
        prop="grade"
        header-align="center"
        align="center"
        label="年级"
      >
      </el-table-column>
      <el-table-column
        prop="classNum"
        header-align="center"
        align="center"
        label="班级"
      >
      </el-table-column>-->
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="分类"
      >
      </el-table-column>
      <el-table-column
        prop="description"
        header-align="center"
        align="center"
        label="简介"
      >
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        label="操作"
        width="218px"
      >
        <template slot-scope="scope">
          <el-button
            v-if="permissions.cms_schoolstudent_edit"
            type="text"
            size="small"
            icon="el-icon-edit"
            @click="addOrUpdateStudentHandle(scope.row.id)"
            >重点关注学生</el-button
          >
          <el-button
            v-if="permissions.cms_schoolstudent_edit"
            type="text"
            size="small"
            icon="el-icon-edit"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="permissions.cms_schoolstudent_del"
            type="text"
            size="small"
            icon="el-icon-delete"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- </div> -->

    <div class="pagination">
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        :total="totalPage"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
      >
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <table-form
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></table-form>
    <!-- 弹窗, 新增 / 修改重点关注学生 -->
    <student-list
      v-if="addOrUpdateStudentVisible"
      ref="addOrUpdateStudent"
      @refreshDataList="getDataList"
    ></student-list>

    <!-- 教师导入弹窗 -->
    <el-dialog
      title="教师导入"
      :visible.sync="importTeacherVisible"
      width="624px"
      class="import-teacher"
    >
      <div v-loading="fullscreenLoading">
        <div class="upload-tips">
          当前仅支持excel文件，
          <el-link
            type="primary"
            :href="
              'https://static.wtjy.com/analy/template/%E6%95%99%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls?time=' +
              time
            "
            >下载Excel模板<i class="el-icon-download"></i
          ></el-link>
        </div>
        <el-upload
          :action="teacherImportURL"
          :show-file-list="false"
          :headers="teacherActionHeaders"
          :before-upload="handleImportBefore"
          :on-success="handleImportOnSuccess"
          :on-error="handleImportOnError"
          :on-progress="handleImportOnProgress"
          drag
          class="teacher-upload"
        >
          <div class="el-upload__text">将文件拖放到此处或点击上传</div>
        </el-upload>
      </div>
    </el-dialog>

    <!-- 重点关注学生导入 -->
    <el-dialog
      title="重点关注学生导入"
      :visible.sync="importFollowStudentVisible"
      width="624px"
      class="import-teacher"
    >
      <div v-loading="fullscreenLoading">
        <div class="upload-tips">
          当前仅支持excel文件，
          <el-link
            type="primary"
            :href="
              'https://static.wtjy.com/cms/template/%E9%87%8D%E7%82%B9%E5%85%B3%E6%B3%A8%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls?time=' +
              time
            "
            >下载Excel模板<i class="el-icon-download"></i
          ></el-link>
        </div>
        <el-upload
          :action="followStudentImportURL"
          :show-file-list="false"
          :headers="teacherActionHeaders"
          :before-upload="handleImportBefore"
          :on-success="handleImportOnSuccess"
          :on-error="handleImportOnError"
          :on-progress="handleImportOnProgress"
          drag
          class="teacher-upload"
        >
          <div class="el-upload__text">将文件拖放到此处或点击上传</div>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getStore } from "@/core/util/store";
import {
  fetchList,
  delObj,
  exportTeacher,
  exportFollowStudent,
} from "@/core/api/school/schoolteacher";
import TableForm from "./schoolteacher-form";
import StudentList from "./student-list";
import { mapGetters } from "vuex";
import { fileDownloadByUrl } from "@/core/util/util";

export default {
  components: {
    TableForm,
    StudentList,
  },
  data() {
    return {
      dataForm: {
        key: "",
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 50,
      totalPage: 0,
      time: new Date().getTime(),
      fullscreenLoading: false,
      dataListLoading: false,
      addOrUpdateVisible: false,
      addOrUpdateStudentVisible: false,
      //导入
      importTeacherVisible: false,
      importFollowStudentVisible: false,
      teacherImportURL: "",
      followStudentImportURL: "",
      teacherActionHeaders: {},
      followStudentActionHeaders: {},
      loading: false,
      //搜索
      teacherName: null,
      CDN: "http://static.wtjy.com/",
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
  },
  created() {
    this.teacherImportURL = "/cms/schoolteacher/import";
    this.followStudentImportURL = "/cms/schoolstudent/import/followstudent";

    this.teacherActionHeaders = {
      Authorization: "Bearer " + getStore({ name: "access_token" }),
      "SCHOOL-ID": getStore({ name: "school_id" }),
    };
    this.followStudentActionHeaders = {
      Authorization: "Bearer " + getStore({ name: "access_token" }),
      "SCHOOL-ID": getStore({ name: "school_id" }),
    };
    this.getDataList();
  },

  methods: {
    getDataList() {
      this.dataListLoading = true;
      this.normalFetchList();
    },
    normalFetchList() {
      fetchList(
        Object.assign({
          current: this.pageIndex,
          size: this.pageSize,
          searchKey: this.teacherName,
        })
      ).then((response) => {
        this.dataList = response.data.data.records;
        this.totalPage = response.data.data.total;
        this.dataListLoading = false;
      });
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 新增 / 修改 重点关注学生
    addOrUpdateStudentHandle(id) {
      this.addOrUpdateStudentVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdateStudent.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm("是否确认删除ID为" + id, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delObj(id);
        })
        .then((data) => {
          if (data && data.data && data.data.code === 0) {
            this.getDataList();
            this.$message.success("删除成功");
          } else {
            var msg = "";
            if (data && data.data && data.data.msg) {
              msg = data.data.msg;
            }
            if (!msg) {
              msg = "删除失败";
            }
            this.$message.error(msg);
          }
        });
    },
    searchTeacher() {
      this.pageIndex = 1;
      this.getDataList();
    },
    //导入
    importHandle() {
      this.importTeacherVisible = true;
    },
    handleImportBefore(file) {
      const isFileValid =
        file.name.endsWith(".xls") || file.name.endsWith(".xlsx");
      if (!isFileValid) {
        this.$message.warning("当前仅支持excel文件");
      } else {
        this.fullscreenLoading = true;
      }
      return isFileValid;
    },
    handleImportOnProgress() {
      this.fullscreenLoading = true;
    },
    handleImportOnSuccess(response, file, fileList) {
      this.fullscreenLoading = false;
      this.handleImportFileRemove(fileList);
      if (response.code != 0) {
        let responseMsg = response.msg;
        if (responseMsg && responseMsg.indexOf("http") == 0) {
          fileDownloadByUrl(responseMsg);
          responseMsg = "导入失败，请下载查看具体明细";
        }
        this.$message({
          showClose: true,
          type: "warning",
          message: responseMsg,
          dangerouslyUseHTMLString: true,
          duration: 20000,
        });
      } else {
        this.$message({
          showClose: true,
          type: "success",
          message: response.data,
          dangerouslyUseHTMLString: true,
          duration: 3000,
        });
        this.importTeacherVisible = false;
        this.importFollowStudentVisible = false;
      }
      this.getDataList();
    },
    handleImportOnError(err, file, fileList) {
      this.fullscreenLoading = false;

      this.handleImportFileRemove(fileList);
    },
    handleImportFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    teacherImportFormSubmit() {
      this.importTeacherVisible = false;
    },
    exportTeacher() {
      this.loading = true;
      exportTeacher().then((response) => {
        if (!response.data.data) {
          this.$message.success("导出失败");
        } else {
          let fileUrl = response.data.data;
          let fileNameArr = fileUrl.split("/");
          let fileName = fileNameArr[fileNameArr.length - 1];
          let req = new XMLHttpRequest();
          req.open("GET", fileUrl, true);
          req.responseType = "blob";
          req.onload = function () {
            let url = window.URL.createObjectURL(req.response);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.click();
          };
          req.send();
          this.$message.success("导出成功");
        }
        this.loading = false;
      });
    },
    exportFollowStudent() {
      this.loading = true;
      exportFollowStudent().then((response) => {
        let fileUrl = response.data.data.url;
        let fileName = response.data.data.schoolName + "-重点关注学生信息.xls";
        let req = new XMLHttpRequest();
        req.open("GET", fileUrl, true);
        req.responseType = "blob";
        req.onload = function () {
          let url = window.URL.createObjectURL(req.response);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
        };
        req.send();
        this.$message.success("导出成功");
      });
    },
    importFollowStudent() {
      this.importFollowStudentVisible = true;
    },
  },
};
</script>
<style lang="scss">
.teacher-manage {
  width: 1220px;
  margin: 28px auto;
  box-shadow: 0 0 2px 2px rgb(0 0 0 / 5%);
  padding: 20px;
  border-radius: 4px;

  .el-radio-button__inner {
    border: none;
    background-color: transparent;
    font-size: 14px;
    padding: 9px 15px;
    border-radius: 4px;
  }
  .el-radio-button:first-child .el-radio-button__inner,
  .el-radio-button:last-child .el-radio-button__inner {
    // border-radius: 0;
    border-left: none;
  }
  .el-radio-button__inner:hover {
    color: #606266;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
    color: #ffffff;
  }

  .search-teacher {
    padding: 20px;
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.05);
    label {
      margin-bottom: 0;
    }
    .search-items {
      display: flex;
      margin-bottom: 8px;
      .el-input {
        width: 500px;
        margin-left: 14px;
      }
      .item-label {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        margin-right: 14px;
      }
    }
  }

  .import-teacher {
    .upload-tips {
      width: 541px;
      font-family: PingFangSC;
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      text-align: justify;
      color: rgba(0, 0, 0, 0.85);
      margin-left: 22px;
      margin-bottom: 10px;
      .el-button--primary i {
        margin-right: 4px;
        color: #fff;
      }
      .el-icon-download {
        color: #409eff;
      }
    }
    .teacher-upload {
      .el-upload-dragger {
        width: 541px !important;
        height: 88px !important;
        margin: 24px 0;
        border-radius: 4px;
        border: dashed 1px #bdbdbd;
        line-height: 88px;
        margin-left: 22px;
      }
    }
  }
  .teacher-detail {
    font-size: 12px;
    margin-bottom: 20px;
    .el-button {
      padding: 0 !important;
    }
  }
  .pagination {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      // background-color: #2474ED;
    }
  }
}
</style>
