<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'" :visible.sync="visible">
    <el-form
      ref="dataForm"
      class="data-from"
      :model="dataForm"
      status-icon
      :rules="rules"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="dataForm.name" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-input v-model="dataForm.phone" placeholder="联系电话"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="dataForm.username" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="dataForm.password" placeholder="密码"></el-input>
      </el-form-item>

      <el-form-item label="科目" prop="subjectName">
        <el-select
          v-model="dataForm.subjectName"
          multiple
          allow-create
          default-first-option
          placeholder="请选择科目"
          style="width: 100%"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!--  <el-form-item
         label="年级"
         prop="grade"
         :rules="dataForm.classNum ? rules.grade : [{ required: false }]"
       >
         <el-select
           v-model="dataForm.grade"
           filterable
           remote
           reserve-keyword
           placeholder="请输入关键词"
           :remote-method="remoteGrade"
           :loading="loading"
           style="width:100%"
           @click.native="remoteGrade"
           @change="remoteClassNum"
         >
           <el-option
             v-for="item in options"
             :key="item.label"
             :label="item.label"
             :value="item.label"
           >
           </el-option>
         </el-select>
       </el-form-item>
       <el-form-item
         label="班级"
         prop="classNum"
         :rules="dataForm.grade ? rules.classNum : [{ required: false }]"
       >
         <el-select
           v-model="dataForm.classNum"
           remote
           reserve-keyword
           :remote-method="remoteClassNum"
           placeholder="请先选择年份级"
           :loading="loading"
           style="width:100%"
         >
           <el-option
             v-for="item in classNumOpt"
             :key="item.value"
             :label="item.label"
             :value="item.value"
           >
           </el-option>
         </el-select>
       </el-form-item> -->
      <el-form-item label="分类" prop="type">
        <el-input v-model="dataForm.type" placeholder="分类"></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input
          v-model.number="dataForm.description"
          placeholder="描述"
        ></el-input>
      </el-form-item>
      <el-form-item hidden prop="delFlag">
        <el-input v-model.number="dataForm.delFlag" hidden></el-input>
      </el-form-item>
      <el-form-item
        v-if="schoolTeacherRoleList.length > 0"
        label="角色"
        prop="type"
      >
        <div v-for="(v, i) in schoolTeacherRoleList" :key="i">
          <div class="role-type">
            <el-radio-group
              v-if="schoolTeacherRoleList.length > 0"
              v-model="v.type"
            >
              <div v-for="(index, key) in v.roleData" :key="key">
                <el-radio :label="index.type">
                  {{ index.name }}
                </el-radio>
              </div>
            </el-radio-group>
            <i class="el-icon-delete" @click="delRole(i)"></i>
          </div>
          <div class="role-msg">
            <template v-if="v.type == 4">
              <el-select v-model="v.roleData[1].level" placeholder="请选择学段">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-model="v.roleData[1].year"
                type="year"
                placeholder="选择年份"
                value-format="yyyy"
                :clearable="false"
              >
              </el-date-picker>
            </template>
            <template v-if="v.type == 3">
              <el-select
                v-model="v.roleData[2].level"
                placeholder="请选择学段"
                @change="changeLevel(v)"
              >
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-model="v.roleData[2].year"
                type="year"
                placeholder="选择年份"
                value-format="yyyy"
                :clearable="false"
              >
              </el-date-picker>
              <el-select
                v-model="v.roleData[2].subjectId"
                placeholder="请选择科目"
              >
                <template v-if="v.roleData[2].level == 2">
                  <el-option
                    v-for="item in juniorSubjectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option
                ></template>
                <template v-else>
                  <el-option
                    v-for="item in highSubjectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
            </template>
            <template v-if="v.type == 2">
              <el-select v-model="v.roleData[3].level" placeholder="请选择学段">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-model="v.roleData[3].year"
                type="year"
                placeholder="选择年份"
                value-format="yyyy"
                :clearable="false"
              >
              </el-date-picker>
              <el-input
                v-model="v.roleData[3].classNums"
                placeholder="请输入班级，使用,隔开"
              ></el-input>
            </template>
            <template v-if="v.type == 1">
              <el-select
                v-model="v.roleData[4].level"
                placeholder="请选择学段"
                @change="changeLevel(v)"
              >
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-model="v.roleData[4].year"
                type="year"
                placeholder="选择年份"
                value-format="yyyy"
                :clearable="false"
              >
              </el-date-picker>
              <el-select
                v-model="v.roleData[4].subjectId"
                placeholder="请选择科目"
              >
                <template v-if="v.roleData[4].level == 2">
                  <el-option
                    v-for="item in juniorSubjectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option
                ></template>
                <template v-else>
                  <el-option
                    v-for="item in highSubjectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-input
                v-model="v.roleData[4].classNums"
                placeholder="请输入班级，使用,隔开"
              ></el-input>
            </template>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="type">
        <el-button type="primary" @click="addRole">添加角色</el-button>
        <span style="color: #d81e06; margin-left: 12px"
          >温馨提示：请勿添加重复角色，重复角色将去重后保存！</span
        >
        <!-- <el-input v-model="dataForm.type" placeholder="分类"></el-input> -->
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getObj,
  addObj,
  putObj,
  getGrade,
  getClassNum,
} from "@/core/api/school/schoolteacher";
import { levelOptions, subjectOptions } from "@/core/util/constdata";
import { filterSubject } from "@/core/util/util";

export default {
  data() {
    const pwdValid = (rule, value, callback) => {
      // [^\x00-\xff]
      // eslint-disable-next-line no-control-regex
      const reg = /[^\x00-\xff]/g;
      if (!reg.test(value)) {
        callback();
      } else {
        callback(Error("请输入数字或英文的标点符号！"));
      }
      callback();
    };
    return {
      roleData: [
        {
          type: 5,
          name: "校领导",
        },
        {
          level: "",
          type: 4,
          year: "",
          name: "年级组长",
        },
        {
          level: "",
          type: 3,
          year: "",
          subjectId: 1,
          name: "备课组长",
        },
        {
          level: "",
          type: 2,
          classNums: "",
          name: "班主任",
          year: "",
        },
        {
          level: "",
          type: 1,
          classNums: "",
          year: "",
          subjectId: 1,
          name: "任课教师",
        },
      ],
      schoolTeacherRoleList: [],

      loading: false,
      visible: false,
      // oldSubjectOptions: subjectOptions,
      subjectOptions: subjectOptions,
      highSubjectOptions: [],
      juniorSubjectOptions: [],
      // subjectOld: [],
      levelOptions: levelOptions(),
      options: [],
      classNumOpt: [],
      dataForm: {
        id: 0,
        userId: 0,
        name: "",
        phone: "",
        type: "",
        username: "",
        password: "",
        grade: "",
        classNums: "",
        subjectId: [],
        subjectName: [],
        description: "",
        createTime: "",
        updateTime: "",
        delFlag: "",
      },
      rules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        /*username: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ], */
        password: [{ validator: pwdValid, trigger: "change" }],
        phone: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
        ],
        /*  subjectName: [
          { required: true, message: "科目不能为空", trigger: "change" }
        ],*/
        grade: [{ required: true, message: "年级不能为空", trigger: "change" }],
        classNums: [
          { required: true, message: "班级不能为空", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    visible() {
      if (!this.visible) {
        Object.assign(this.$data.dataForm, this.$options.data().dataForm);
        this.schoolTeacherRoleList = [];
      }
    },
  },
  created() {
    this.initOption();
  },
  methods: {
    initOption() {
      // this.subjectOptions = [...this.oldSubjectOptions];
      // console.log(this.subjectOptions);
      // this.subjectOptions.splice(4, 0, { value: 11, label: "道德与法治" });
      this.juniorSubjectOptions = filterSubject(2);
      this.highSubjectOptions = filterSubject(3);
    },
    changeLevel(value) {
      value.roleData.map((index) => {
        if (index.type == value.type) {
          index.subjectId = 1;
        }
      });
    },
    delRole(i) {
      this.schoolTeacherRoleList.splice(i, 1);
    },
    addRole() {
      let data = JSON.parse(JSON.stringify(this.roleData));
      data.forEach((index) => {
        if (index.year == "") {
          index.year = new Date().getFullYear().toString();
        }
      });
      this.schoolTeacherRoleList.push({
        type: "",
        roleData: data,
      });
    },
    remoteGrade(query) {
      setTimeout(() => {
        getGrade(
          Object.assign({
            query: query,
          })
        ).then((res) => {
          this.options = res.data.data;
        });
      }, 200);
    },
    remoteClassNum() {
      this.dataForm.classNums = "";
      getClassNum(
        Object.assign({
          grade: this.dataForm.grade,
        })
      ).then((res) => {
        this.classNumOpt = res.data.data;
      });
    },
    init(id) {
      if (id) this.schoolTeacherRoleList = [];
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          getObj(this.dataForm.id).then((response) => {
            this.dataForm = response.data.data;
            if (this.dataForm.subjectName) {
              this.dataForm.subjectName =
                response.data.data.subjectName.split(",");
            }
            this.dataForm.schoolTeacherRoleList.forEach((i) => {
              let data = JSON.parse(JSON.stringify(this.roleData));
              // data[i.type - 1] = i;
              data.forEach((index, a) => {
                if (i.type == index.type) {
                  data[a] = i;
                  data[a].name = this.roleData[a].name;
                  if (data[a].level) {
                    data[a].level = data[a].level.toString();
                  }
                  if (data[a].year) {
                    data[a].year = data[a].year.toString();
                  }
                }
              });
              this.schoolTeacherRoleList.push({
                type: i.type,
                roleData: data,
              });
            });
            // console.log(this.schoolTeacherRoleList);
          });
          getGrade(
            Object.assign({
              query: "",
            })
          ).then((res) => {
            this.options = res.data.data;
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      let subList = [];
      let roleType = false;
      let levelType = false;

      if (this.schoolTeacherRoleList.length == 0) {
        this.$message.warning("至少添加一个角色");
        return;
      }
      this.schoolTeacherRoleList.forEach((i) => {
        i.roleData.forEach((index) => {
          if (index.type == i.type) {
            subList.push(index);
          }
        });
        if (i.type == "") {
          roleType = true;
        }
      });
      if (roleType) {
        this.$message.warning("请选择所有已添加角色");
        return;
      }
      let yearType = false;
      let classType = false;
      subList.forEach((index) => {
        if (index.type != 5 && !index.year) {
          yearType = true;
        }
        if ((index.type == 1 || index.type == 2) && !index.classNums) {
          classType = true;
        }
        if (index.type != 5 && !index.level) {
          levelType = true;
        }
        if (index.classNums) {
          index.classNums = index.classNums.replace(/，/g, ",");
        }
      });
      if (yearType) {
        this.$message.warning("请选择角色年级");
        return;
      }
      if (classType) {
        this.$message.warning("请输入角色班级");
        return;
      }
      if (levelType) {
        this.$message.warning("请选择学段");
        return;
      }
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.dataForm));
          data.schoolTeacherRoleList = subList;
          let arr = [];
          let arrID = [];
          data.subjectId = [];
          if (this.dataForm.subjectName) {
            this.dataForm.subjectName.forEach((i) => {
              this.subjectOptions.forEach((a) => {
                if (a.label == i) {
                  arrID.push(a.value);
                  arr.push(a.label);
                }
              });
            });
          }
          data.subjectName = arr.join(",");
          data.subjectId = arrID.join(",");
          if (this.dataForm.id) {
            data.updateTime = null;
            putObj(data).then((data) => {
              if (data && data.data && data.data.code === 0) {
                this.$message.success("修改成功");
                Object.assign(
                  this.$data.dataForm,
                  this.$options.data().dataForm
                );
                this.visible = false;
                this.$emit("refreshDataList");
              } else {
                var msg = "";
                if (data && data.data && data.data.msg) {
                  msg = data.data.msg;
                }
                if (!msg) {
                  msg = "修改失败";
                }
                this.$message.error(msg);
              }
            });
          } else {
            data.delFlag = 0;
            addObj(data).then((data) => {
              if (data && data.data && data.data.code === 0) {
                this.$message.success("添加成功");
                Object.assign(
                  this.$data.dataForm,
                  this.$options.data().dataForm
                );
                this.visible = false;
                this.$emit("refreshDataList");
              } else {
                var msg = "";
                if (data && data.data && data.data.msg) {
                  msg = data.data.msg;
                }
                if (!msg) {
                  msg = "添加失败";
                }
                this.$message.error(msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.data-from {
  .role-type {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 13px;
    .el-radio-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    .el-icon-delete {
      margin-bottom: 0.5rem;
      margin-left: 40%;
      cursor: pointer;
    }
  }

  .role-msg {
    display: flex;
    margin-bottom: 12px;
    > div {
      margin-right: 20px;
    }
    .el-input {
      width: 220px;
    }
  }
}
</style>
