<template>
  <div>
    <el-dialog title="重点关注学生" :visible.sync="visible">
      <div class="search-teacher">
        <div v-if="permissions.cms_schoolstudent_add" class="search-items">
          <el-button type="primary" @click="addFollowStudent()"
            ><i class="el-icon-plus"></i>添加</el-button
          >
          <el-input v-model="searchKey" placeholder="请输入搜索词"></el-input>
          <el-button type="primary" @click="search()">
            <i class="el-icon-search"></i>搜索
          </el-button>
        </div>
      </div>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        style="width: 100%"
      >
        <el-table-column prop="schoolName" label="学校" width="180">
        </el-table-column>
        <el-table-column prop="gradeName" label="年级" width="180">
        </el-table-column>
        <el-table-column prop="classNum" label="班级"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="studentNo" label="学籍号"> </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="操作"
          width="218px"
        >
          <template slot-scope="scope">
            <el-button
              v-if="permissions.cms_schoolstudent_del"
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="deleteHandle(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="pageSize"
          :total="totalPage"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <!--      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>-->
      </span>
    </el-dialog>
    <el-dialog title="新增" :visible.sync="addStudentVisible">
      <el-form
        ref="dataForm"
        class="data-from"
        :model="dataForm"
        status-icon
        :rules="rules"
        label-width="80px"
        @keyup.enter.native="dataFormSubmit()"
      >
        <template>
          <el-form-item label="" prop="name">
            <el-select
              v-model="dataForm.gradeId"
              placeholder="请选择学段"
              @change="changeLevel()"
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="dataForm.classNum"
              placeholder="请选择班级"
              @change="changeClassNum()"
            >
              <el-option
                v-for="item in classOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-table
          v-loading="studentDataListLoading"
          :data="studentDataList"
          style="width: 100%"
        >
          <!--          <el-table-column prop="gradeName" label="年级" width="180">
          </el-table-column>
          <el-table-column prop="classNum" label="班级"> </el-table-column>-->
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="studentNo" label="学籍号"> </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="操作"
            width="218px"
          >
            <template slot-scope="scope">
              <el-button
                v-if="!showdel(scope.row) && permissions.cms_schoolstudent_add"
                type="text"
                size="small"
                icon="el-icon-plus"
                @click="addFollow(scope.row)"
                >添加</el-button
              >
              <!--              permissions.cms_schoolstudent_del-->
              <el-button
                v-if="showdel(scope.row) && permissions.cms_schoolstudent_del"
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="deleteHandle(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="pagination">
        <el-pagination
          :current-page="addPage"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="addSize"
          :total="addTotal"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="addSizeChangeHandle"
          @current-change="addCurrentChangeHandle"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStudentVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  list,
  addObj,
  delObj,
  getGrade,
  getClassNum,
  studentcontact,
} from "@/core/api/school/studentlist";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dataList: [],
      studentDataList: [],
      dataForm: {
        gradeId: "",
        classNum: "",
        teacherId: "", // 老师ID
        id: "", // 学生ID
      },
      rules: {},
      pageIndex: 1,
      pageSize: 50,
      totalPage: 0,
      addPage: 1,
      addSize: 50,
      addTotal: 0,
      dataListLoading: false,
      studentDataListLoading: false,
      visible: false,
      addStudentVisible: false,
      teacherId: 0,
      searchKey: "",
      gradeOptions: [],
      classOptions: [],
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
  },
  methods: {
    showdel(val) {
      let type = false;
      let arr = this.dataList.filter((i) => i.id == val.id);
      if (arr.length > 0) {
        type = true;
      }
      return type;
    },
    search() {
      this.pageIndex = 1;
      this.getDataList();
    },
    getDataList() {
      this.dataListLoading = true;
      this.normalFetchList();
    },
    normalFetchList() {
      list(
        Object.assign({
          current: this.pageIndex,
          size: this.pageSize,
          teacherId: this.teacherId,
          searchKey: this.searchKey,
        })
      ).then((response) => {
        this.dataList = response.data.data.records;
        this.totalPage = response.data.data.total;
        this.dataListLoading = false;
        this.visible = true;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 每页数
    addSizeChangeHandle(val) {
      this.addSize = val;
      this.addPage = 1;
      this.getDataList();
    },
    // 当前页
    addCurrentChangeHandle(val) {
      this.addPage = val;
      this.getDataList();
    },
    init(id) {
      this.teacherId = id;
      this.searchKey = "";
      this.getDataList();
    },
    addFollowStudent() {
      this.dataForm.gradeId = "";
      this.dataForm.classNum = "";
      this.studentDataList = [];
      this.addStudentVisible = true;
      getGrade().then((response) => {
        this.gradeOptions = response.data.data;
      });
    },
    changeLevel() {
      if (!this.dataForm.gradeId) {
        this.$message.error("请先选择学段");
      }
      this.dataForm.classNum = "";
      getClassNum(
        Object.assign({
          grade: this.dataForm.gradeId,
        })
      ).then((response) => {
        this.classOptions = response.data.data;
      });
    },
    changeClassNum() {
      studentcontact(
        Object.assign({
          gradeId: this.dataForm.gradeId,
          classNum: this.dataForm.classNum,
        })
      ).then((response) => {
        this.studentDataList = response.data.data.records;
      });
    },
    // 表单提交
    dataFormSubmit() {},
    addFollow(row) {
      this.dataForm.id = row.id;
      this.dataForm.teacherId = this.teacherId;
      let data = JSON.parse(JSON.stringify(this.dataForm));
      addObj(data).then(() => {
        this.normalFetchList();
      });
    },

    deleteHandle(row) {
      if (!row.teacherId) {
        row.teacherId = this.teacherId;
      }
      this.$confirm("是否确认删除ID为" + row.id, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delObj(
            Object.assign({
              id: row.id,
              teacherId: row.teacherId,
            })
          );
        })
        .then((data) => {
          if (data && data.data && data.data.code === 0) {
            this.getDataList();
            this.$message({
              type: "success",
              message: "删除成功",
              showClose: true,
            });
          } else {
            var msg = "";
            if (data && data.data && data.data.msg) {
              msg = data.data.msg;
            }
            if (!msg) {
              msg = "删除失败";
            }
            this.$message.error(msg);
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.data-from {
  .role-type {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 13px;
    .el-radio-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    .el-icon-delete {
      margin-bottom: 0.5rem;
      margin-left: 40%;
      cursor: pointer;
    }
  }

  .role-msg {
    display: flex;
    margin-bottom: 12px;
    > div {
      margin-right: 20px;
    }
    .el-input {
      width: 220px;
    }
  }
}
</style>
