import request from "@/core/services/axios";

export function list(query) {
  return request({
    url: "/cms/schoolteacher/follow/student/page",
    method: "get",
    params: query,
  });
}

export function delObj(query) {
  return request({
    url: "/cms/schoolteacher/delfollowsutdent",
    method: "delete",
    params: query,
  });
}

export function addObj(data) {
  return request({
    url: "/cms/schoolteacher/addfollowsutdent",
    method: "post",
    data: data,
  });
}
export function getGrade(query) {
  return request({
    url: "/cms/schoolteacher/getGrade",
    method: "get",
    params: query,
  });
}
export function getClassNum(query) {
  return request({
    url: "/cms/schoolteacher/getClassNum",
    method: "get",
    params: query,
  });
}
export function studentcontact(query) {
  return request({
    url: "/cms/schoolstudent/page",
    method: "get",
    params: query,
  });
}
